.customer_box{
    margin: 2.5rem 1rem;

    h3.box_title{
        font-family: Arial, Helvetica, sans-serif;
        margin-bottom: 1rem;
        font-weight: 550;
    }

    &__selection{
        display: flex;
        flex-direction: column;
        font-size: .9rem;
        width: 100%;
        margin-bottom: 1rem;

        &__options{
            display: grid;
            grid-auto-flow: column;
            gap: .5rem;
            margin-bottom: 1rem;

            .option{
                cursor: pointer;
                font-weight: 600;
                border-radius: .5rem;
                margin: .5rem;
                padding: .5rem;
                justify-self: center;
                border: 1px solid #333;
                color: #333;
            }

            .option__active{
                border: 1px solid #333;
                background-color: #333;
                color: white;
            }
        }
    }

    .form-control--outline, .form-control--outline:focus, .form-control--outline:active, .form-control--outline:hover{
        border: 1px solid #ced4da;
        color: #333;
    }

    .text-outline{
        display: grid;
        grid-auto-flow: column;
        justify-content: center;
        align-content: center;
        gap: .5rem;
        margin: 0;
        
        .icon{
            align-self: center;
        }

        .green-icon{
            color: green;
        }
    }

    .btn-block, .btn-disabled{
        border-radius: 1rem;
        height: 3rem;
        display: block;
        width: 100%;
    }

    .btn-block{
        background-color: #333;
        color: white;
    }

    .btn-block:hover{
        background-color:white ;
        color:  #333;
        border: 1px solid  #333;
    }
}