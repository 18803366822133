// Font
$laser: 'LaserMetal', sans-serif;

// Colors
$white: #ffffff;
$black: #212529;
$grey: #f2f0f0;
$grey-dark: #ced4da;
$blue: #0069ff;
$red: #e32636;
$green: #28a745;
$yellow:#ff9800;

// Shadows
$general-shadow: 0 2px 4px rgba(50, 50, 93, 0.1);

// Modals
$modals-padding: 56px 20px 70px 20px;
