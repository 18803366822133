@mixin flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
}

@mixin flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

@mixin zero-hard {
  padding: 0;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  margin-left: 0;
}

@mixin zero-soft {
  padding: 0;
  margin: 0;
}

@mixin clean-webkit {
-webkit-margin-before: 0!important;
  -webkit-margin-after: 0!important;
  -webkit-margin-start: 0!important;
  -webkit-margin-end: 0!important;
  -webkit-padding-before: 0!important;
  -webkit-padding-after: 0!important;
  -webkit-padding-start: 0!important;
  -webkit-padding-end: 0!important;
}

@mixin clean-list {
  list-style: none;
  list-style-image: none;
  list-indent: 0;
  list-style-type: none;
  list-style-position: outside;
  padding-inline-start: 0px;
  margin-block-start: 0!important;
  margin-block-end: 0!important;
}

@mixin clean-href {
  color: inherit;
  text-decoration: none;
  outline: none;
  border: 0;
  @include zero-hard;
  &:hover, &:active, &:visited, &:focus {
    text-decoration: none;
    color: inherit;
    outline: none;
    border: 0;
    @include zero-hard;
  }
}

@mixin clean-button {
  border: 0;
  outline: 0;
  box-shadow: none;
  @include zero-hard;
  &:hover, &:active, &:visited, &:focus {
    border: 0;
    outline: 0;
    box-shadow: none;
    @include zero-hard;
  }
}

@mixin animate {
  transition: all 0.25s ease-in-out;
}

@mixin shadow {
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
}

@mixin text-shadow {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
}

@mixin text-overflow {
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}