@import "../../../../assets/scss/variables";

.footer {
  padding: 40px 0;
  background-color: $grey;

  a {
    color: inherit;
  }

  h5 {
    font-weight: 700;
    margin-top: 16px;

    margin-left: 8px;
  }

  // &__centerFooter {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  // }

  .list-unstyled {
    li:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    @media (max-width: 767px) {
      margin-bottom: 2rem;
    }
  }

  // &__logo {
  //   margin-bottom: 0.5rem;

  //   img {
  //     max-width: 150px;
  //   }

  //   @media (max-width: 767px) {
  //     text-align: center;
  //     margin-bottom: 2rem;
  //   }
  // }

  &__newFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -16px;
    justify-content: center;

  }

  img {
    max-width: 140px;
  }

  @media (min-width: 992px) and (max-width: 1920.98px) {

    &__newFooter {
      display: flex;
      flex-direction: column;

    }

  }

  .delGourmet #delGourmet {
    display: none;
  }

  &__link {
    display: inline-block;
    color: $black;

    &::first-letter {
      text-transform: uppercase;
    }

    &:hover {
      color: rgba($black, 0.4);
      text-decoration: none;
    }
  }

  @media (min-width: 768px) {
    padding: 70px 0;
  }

  &__logo_seperador {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }
}