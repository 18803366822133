.fade-appear,
.fade-enter {
  z-index: 1;
  opacity: 0;
  transform: translateY(-0.5rem);
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: translateY(0);
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: translateY(-0.5rem);
}