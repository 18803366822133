@import './variables';

.wrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 40vh;

  @media (max-width: 576px) {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__title {
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1.5rem;

    &--underline {
      padding-bottom: 1rem;
      border-bottom: 1px solid $grey;
    }
  }
}

.grid-layout {
  display: grid;
  grid-template-columns: 300px 1fr;
  height: 100%;
  gap: 0.5rem;

  &__wrapper {
    overflow: auto;
    background-color: $white;
    box-shadow: $general-shadow;
    padding: 1rem;
    border-radius: 4px;

    &--alt {
      padding: 0;
      display: flex;
    }
  }
}

.cart-wrapper {
  max-height: 400px;
  overflow: auto;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $grey;
}

.custom-table {
  box-shadow: $general-shadow;
  border-radius: 4px;

  .rdt_TableHeadRow {
    padding: 0.5rem 0;
  }

  .rdt_TableCol {
    font-weight: 700;
    font-size: 1rem;
  }
}

.form-control-code {
  display: flex;
  border: 1px solid $grey-dark;
  border-radius: 0.25rem;
  padding: 0.375rem;
  height: calc(1.5em + 0.75rem + 2px);

  &__box {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__line {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: $grey-dark;
    padding: 0 10px;
  }

  &__input {
    width: 50%;
    outline: none;
    padding: 0;
    text-align: center;
    border: none;
    border-bottom: 1px solid $grey-dark;
    font-weight: 700;
    border-radius: 0;

    &:focus {
      border-bottom-color: var(--clr-primary);
    }

    &::placeholder {
      color: $grey-dark;
    }
  }

  &__link {
    color: var(--clr-primary);
    cursor: pointer;
  }
}

.select-outline {
  &__menu {
    z-index: 100 !important;
  }
}

.btn--white {
  background-color: $white;
  color: $black;

  &:hover,
  &:focus {
    background-color: darken($white, 2%);
    color: $black;
  }
}

.btn--none {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;

  &:hover,
  &:focus {
    box-shadow: none !important;
    transform: translateY(0) !important;
  }
}

.btn--line-color {
  color: var(--clr-btn);

  &::after {
    content: '';
    display: block;
    width: 100%;
    transform: scale(0);
    height: 2px;
    transition: all 0.3s ease;
    transform-origin: center;
    background-color: var(--clr-btn);
  }

  &:hover,
  &:focus {
    color: var(--clr-btn);

    &::after {
      transform: scale(1);
    }
  }
}

.btn--color {
  background-color: var(--clr-btn);
  color: var(--clr-text-btn);

  &:hover {
    color: var(--clr-text-btn);
  }

}
.btn--center{
  @media (max-width: 425px) {
    margin: 0 auto;
    display: flex;
    margin-bottom: 1rem;
  }
}
.btn-toolbar {
  display: flex;
  margin-bottom: -0.5rem;

  .btn {
    margin-bottom: 0.5rem;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.radio-outline {
  --clr-bg: #fff;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  position: relative;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    content: '';
    top: 4px;
  }

  &:before {
    background: transparent;
    transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
    border: 1px solid var(--clr-bg);
  }

  &:after {
    background: var(--clr-bg);
    transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
    transform: scale(0);
  }

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  &--active {
    &::after {
      transition: all 0.3s ease;
      transform: scale(0.5);
    }
  }
}

.modal-close {
  position: fixed;
  top: calc(7.5vh + 0.5rem);
  right: 1rem;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 30px;
  height: 30px;
  outline: none;
  border: none;
  border-radius: 50%;
  background: rgba($black, 0.65);
  color: $white;
  transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);

  @media (min-width: 576px) {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .close {
    color: $white;
    text-shadow: none;
  }

  &:hover {
    background: rgba($black, 0.8);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  &--static {
    position: relative;
    top: 1.25rem;
    left: 2rem;
    background: transparent;
    color: $black;
    font-size: 30px;

    &:hover {
      background: transparent;
      box-shadow: none;
    }
  }
}

.modal-content {
  @media (max-width: 767px) {
    max-height: 85vh;
  }
}

.modal-xl {
  @media (min-width: 768px) and (max-width: 1199px) {
    max-width: 950px;
  }
}
