.main-wrapper_salepoint {
  overflow: auto;
  flex-grow: 1;
  background-color: #fafafa;
  padding-top: 57px;
  padding-left: 70px;

  @media (min-width: 768px) {
    padding-left: 0;
  }
}

.dlvry-salepoint-content-wrapper {
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-width: 992px) {
    min-width: 992px;
  }

  .dlvry-salepoint-body-wrapper {
    width: 100%;
    height: 100%;

    &.margins {
      padding: 1.25rem;
    }

    &--column {
      display: flex;
      flex-direction: column;
    }
  }
}

.dlvry-desktop-app{
  position: absolute;
  right: 10px;
  top: 8px;
  z-index: 2;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}
