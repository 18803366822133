body {
  // font-family: "DINPro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  display: flex;
  height: 100vh;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

a, .btn {
  transition: all 0.45s cubic-bezier(0.33, 1, 0.68, 1);
}

.btn {
  font-weight: 700;
  box-shadow: 0 2px 4px rgba(50, 50, 93, 0.1);

  &:hover:not(.active):not(:disabled), &:focus {
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.1);
  }

  &.disabled, &:disabled {
    background-color: #e0e0e0 !important;
    color: #9e9e9e !important;
    cursor: not-allowed;
  }
}

.min-50vh {
  min-height: 50vh;
}

.card {
  border: none;
  box-shadow: 0 2px 4px rgba(50, 50, 93, 0.1);
}

.card-header {
  border-bottom: none;
}

.input-group-text {
  background-color: $white;
  border-right-color: $white;
  padding-right: 0;
}

.input-group > .form-control {
  border-left-color: transparent;

  &:focus {
    box-shadow: none;
    border-color: #ced4da;
    border-left-color: transparent;
  }
}

.input-group-append .btn {
  &:hover:not(.active):not(:disabled), &:focus {
    transform: translateY(0);
  }
}

.invalid-feedback {
  display: block;
}