@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";

button{
  @include flex-row-center;
  > i{
    display:inline-block;
    margin-right: 5px;
  }
  &.btn--blue{
    background-color: $blue;
    color: $white;
    > i {
      color: $white;
    }
    &:hover{
      background-color: lighten($blue, 5%);
      color: $white;
      > i {
        color: $white;
      }
    }
  }
  &.btn--red{
    background-color: $red;
    color: $white;
    > i {
      color: $white;
    }
    &:hover{
      background-color: lighten($red, 5%);
      color: $white;
      > i {
        color: $white;
      }
    }
  }
  &.btn--black{
    background-color: $black;
    color: $white;
    > i {
      color: $white;
    }
    &:hover{
      background-color: lighten($black, 5%);
      color: $white;
      > i {
        color: $white;
      }
    }
  }
  &.btn--yellow{
    background-color: $yellow;
    color: $white;
    > i {
      color: $white;
    }
    &:hover{
      background-color: lighten($yellow, 5%);
      color: $white;
      > i {
        color: $white;
      }
    }
  }
  &.btn--green{
    background-color: $green;
    color: $white;
    > i {
      color: $white;
    }
    &:hover{
      background-color: lighten($green, 5%);
      color: $white;
      > i {
        color: $white;
      }
    }
  }
}