@import "~bootstrap/scss/bootstrap";
// @import "./assets/fonts/din/stylesheet.css";
// @import "./assets/fonts/din-cond/stylesheet.css";
// @import "./assets/fonts/laser-metal/stylesheet.css";
// @import "./assets/fonts/bould/stylesheet.css";
@import "./assets/fonts/fontawesome/scss/fontawesome.scss";
@import "./assets/fonts/fontawesome/scss/regular.scss";
@import "./assets/fonts/fontawesome/scss/brands.scss";
@import "./assets/fonts/fontawesome/scss/solid.scss";
@import "./assets/scss/app";
@import "./assets/scss/general";

// Mobile failed - commented
// body {
//     touch-action:none;
// }