.search-toggle{
    display: none;
}

.no-visible{
    visibility: hidden;
}

.fullsearch{
    position: absolute;
    background-color: white;
    top: 5rem;
    left: 0;
    width: 100vw;
    padding: 1rem;
    z-index: 0;
    transition: all 0.40s ease-in-out;
    transform: translateY(-30vh);

    @media (min-width: 426px) and(max-width: 1024px) {
        padding: 2rem;
    }

    @media (min-width: 1025px) and(max-width: 1440px) {
        padding: 2rem 5rem;
    }

    @media (min-width: 1441px) {
        padding: 2rem 8rem;
    }

    &__box{
        width: 100%;
        transition: all 0.40s ease-in-out;
        // transform: translateY(-30vh);

        &--close{
            align-self: center;
            cursor: pointer;

            .close-icon{
                background-color: rgba($color: #000000, $alpha: .7);
                color: white;
                border-radius: 50%;
            }
        }

        &__product{
            display: flex;
            justify-items: start;
            align-items: center;
            margin-bottom: .6rem;
            cursor: pointer;
            
            &__image > img{
                width: 4rem;
                max-width: 4rem;
                min-width: 4rem;
                border-radius: 50%;
                margin-right: 1rem;
            }
    
            &__name {
                border-bottom: 1px solid rgba($color: #333333, $alpha: .5);
                padding-bottom: 1rem;
                width: 100%;
            }
        }

        &__product:last-child{
            margin-bottom:0;
        }
    }
}

#search-toggle:checked ~ .fullsearch {
    visibility: visible;
    transform: translateY(17.5vh);
    z-index: 9;

    @media (min-width: 376px) and(max-width: 509px) {
        transform: translateY(14vh);
    }
    
    @media (min-width: 510px) and(max-width: 768px) {
        transform: translateY(13.5vh);
    }

    @media (min-width: 769px) and(max-width: 1024px) {
        transform: translateY(8vh);
    }

    @media (min-width: 1025px) and(max-width: 1440px) {
        transform: translateY(2vh);
    }

    @media (min-width: 1441px){
        transform: translateY(.5vh);
    }

    .fullsearch__box {
        // transform: translateY(0vh);
        visibility: visible;
    }
}