@import "./variables";

.subtitle {
  // font-family: "DINPro-Cond", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.sale {
  color: rgba($black, 0.5);
  text-decoration: line-through;
}

.text-outline {
  text-align: center;
  color: $black;
}

.w-3rem {
  width: 3rem;
}

.h-3rem {
  height: 3rem;
}